/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.viewport {
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .4);
}

